// import images and icons 
import Frontimg1 from "../../images/nftGallary/1.svg"
import Frontimg2 from "../../images/nftGallary/2.svg"
import Frontimg3 from "../../images/nftGallary/3.svg"
import Frontimg4 from "../../images/nftGallary/4.svg"
import Frontimg5 from "../../images/nftGallary/5.svg"
import Frontimg6 from "../../images/nftGallary/6.svg"
import Frontimg7 from "../../images/nftGallary/7.svg"
import Frontimg8 from "../../images/nftGallary/8.svg"
import Frontimg9 from "../../images/nftGallary/9.svg"
import Frontimg10 from "../../images/nftGallary/10.svg"
import Frontimg11 from "../../images/nftGallary/11.svg"
import Frontimg12 from "../../images/nftGallary/12.svg"
import Frontimg13 from "../../images/nftGallary/13.svg"
import Frontimg14 from "../../images/nftGallary/14.svg"
import Frontimg15 from "../../images/nftGallary/15.svg"
import Frontimg16 from "../../images/nftGallary/16.svg"
import Frontimg17 from "../../images/nftGallary/17.svg"
import Frontimg18 from "../../images/nftGallary/18.svg"
import Frontimg19 from "../../images/nftGallary/19.svg"
import Frontimg20 from "../../images/nftGallary/20.svg"
import Frontimg21 from "../../images/nftGallary/21.svg"
import Frontimg22 from "../../images/nftGallary/22.svg"
import Frontimg23 from "../../images/nftGallary/23.svg"
import Frontimg24 from "../../images/nftGallary/24.svg"
import Frontimg25 from "../../images/nftGallary/25.svg"




export const NftgallaryData = [
    {
        id: "01",
        frontImg: Frontimg1,
        title: "Ethan from Thirst (any menu item)",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description:",
        subtitleText1: "Blue Powerade and Domino’s pizza ",
        subtitleText2: "On the @thirstdrinks Instagram stories talking to the Thirsty Moms (Nation?)",
        subtitleText3: "Mostly known as “Ethan from Thirst”, Ethan is the president and co-founding entrepreneur behind Thirst. Hard work, content creation, kindness, and charm are his go-to characteristics leading the Thirsty Nation. ",
    },
    {
        id: "02",
        frontImg: Frontimg2,
        title: "Free Popcorn (any menu item)",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description:",
        subtitleText1: "Freshly popped warm popcorn with butter and salt…FREE OF CHARGE",
        subtitleText2: "Near any drive thru window trying to pop onto any order going out the window",
        subtitleText3: "Mr. Free Popcorn is everyone’s best friend. After all, he was invented just to make customers smile! He’s charming, friendly, outgoing and determined to add that extra “wow” to the Thirst experience. ",
    },
    {
        id: "03",
        frontImg: Frontimg3,
        title: "Pretzel Bite Cup",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description:",
        subtitleText1: "Fresh baked pretzel dough baked to perfection, tossed in butter, and sprinkled with pretzel salt",
        subtitleText2: "Making salty comments about how messy drinks are on the finish table. ",
        subtitleText3: "Mr. Pretzel Cup is as salty as they come! He’s always got some sass up his sleeve no matter what time of day. He knows he’s a top seller on the Thirst menu, and he’s proud of it. ",
    },
    {
        id: "04",
        frontImg: Frontimg4,
        title: "Beignet Bag",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Freshly fried beignets covered in powdered sugar and tossed in a white bag",
        subtitleText2: "Wiping up powdered sugar on the beignet table so it doesn’t bother anyone   ",
        subtitleText3: "Mrs. Beignet Bag is the happiest, most grateful, jolly soul of all the Thirsty Creatures. She is constantly counting her blessings and doing everything she can for others. She doesn’t have a grumpy grain of powdered sugar in her bag–it's all sweetness with her.",
    },
    {
        id: "05",
        frontImg: Frontimg5,
        title: "Chocolate Chip Cookie",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "served warm, made with semi-sweet chips, and baked fresh at each store every morning",
        subtitleText2: "Kicking back and relaxing in the heater box on a lawn chair ",
        subtitleText3: "Mr. Chocolate Chip is a wise old man. He has earned every chip and seen it all as a staple on the Thirst menu since day one. He’s warm, kind, and inviting and serves as a mentor to younger and less popular menu items. ",
    },
    {
        id: "06",
        frontImg: Frontimg6,
        title: "Sugar Cookie",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Soft and squishy, a strong hint of vanilla, orange and white sprinkles, and fresh baked at each store every morning",
        subtitleText2: "Admiring her sprinkles in the mirror",
        subtitleText3: "“Miss Sugar” as they call her, is so sweet and cute that all the Thirsty boys are falling for her. She’s tough to impress but sweeter than the sugar that makes her. ",
    },
    {
        id: "07",
        frontImg: Frontimg7,
        title: "Scotcharoo",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Peanut butter rice krispie base with a milk chocolate and butterscotch topcoat",
        subtitleText2: "Winking at Little Mama as she goes out the window  ",
        subtitleText3: "Mr. Roo is the coolest creature of them all. His smooth peanut butter base enchanting you when he looks you in the eyes. He’s known for his cool shades, slick dance moves, and his groovy butterscotch/chocolate topcoat.",
    },
    {
        id: "08",
        frontImg: Frontimg8,
        title: "Pretzel Dog",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "All-beef hot dog wrapped in classic pretzel dough and topped with butter and salt",
        subtitleText2: "Doing stand up comedy on top of the soda machines while sodas are filling up  ",
        subtitleText3: "Mr. Dog is known to be the jokester of the group. Always doggin’ around, pulling pranks, hiding in his pretzel wrap, and trying to make everyone laugh. He’s a snappy dresser, tall, loud, and hard to miss, especially when he gets into the ketchup and mustard stash…",
    },
    {
        id: "09",
        frontImg: Frontimg9,
        title: "Weekend Feature",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "A surprise item available  at all Thirst stores every Friday and Saturday",
        subtitleText2: "Running from the paparazzi on Thursday nights",
        subtitleText3: "“Miss Weekend” as they call her is all about the glamour. She’s constantly hounded by the Thirsty paparazzi who want to know what form she’ll be presenting that weekend. Fashion, popularity, and keeping things exciting is what she does best.",
    },
    {
        id: "10",
        frontImg: Frontimg10,
        title: "Dr. McCreamy",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Dr. Pepper base, raspberry puree, coconut cream",
        subtitleText2: "Flirting with Mrs. Hissy Fit around the Millcreek store.",
        subtitleText3: "Known as the “golden boy” of Thirst signature mixers,  Dr. McCreamy is smooth, charismatic, kind, and honest.  He is the best-selling item on the Thirst menu. He is confident, handsome, kind, honest, and of course he is smooth with the ladies.",
    },
    {
        id: "11",
        frontImg: Frontimg11,
        title: "Bluebelle ",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Mountain Dew, blue curacao, coconut, passionfruit, coconut cream",
        subtitleText2: "Bossing the soda fountain heads about how to do their job better.",
        subtitleText3: "“Miss Blue” may be blue at her base, but she’s a red hot tamale when it comes to her personality. She will not take no for an answer. Have it Miss Blue’s way, or hit the highway! ",
    },
    {
        id: "12",
        frontImg: Frontimg12,
        title: "Fresh Prince",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Lemonade, strawberry, raspberry, fresh lemon, fresh lime, mixed berries",
        subtitleText2: "Cheering drinks on and wishing them luck as they get handed out the window",
        subtitleText3: "The spirit of youth lives within Fresh Prince. He’s a young, energetic, happy, optimistic boy that tries to make the most of every moment. He lives up to the sweetness of his recipe every day with how he interacts with others. ",
    },
    {
        id: "13",
        frontImg: Frontimg13,
        title: "Little Mama",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Diet Coke, coconut, fresh lime",
        subtitleText2: "Making sure drinks” lids are fully secured before they go out the window",
        subtitleText3: "The mama of all Thirst Creatures, Little Mama ain’t so little. She was named after the customers that love to drink her the most, but has since assumed the “mom” role to everyone around her. ",
    },
    {
        id: "14",
        frontImg: Frontimg14,
        title: "Johnny & June",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Sprite, tiger’s blood, coconut cream",
        subtitleText2: "Daydreaming of new drink combinations in the cream fridge",
        subtitleText3: "Known mostly as “JJ”, Johnny & June is a thinker and a creator. He’s always asking questions, proposing new ideas, and opening his mind to the possibilities of the future.",
    },
    {
        id: "15",
        frontImg: Frontimg15,
        title: "Smooth Classic",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Coke, coconut, vanilla, half & half",
        subtitleText2: "Signing autographs in the drive-thru line ",
        subtitleText3: "Almost as classy as he is smooth, “Mr. Smooth” as they call him is a purebred Coca Cola mixer. The original Coke flowing through him gives him outrageous confidence and style on the soda grate. ",
    },
    {
        id: "16",
        frontImg: Frontimg16,
        title: "Yer Killin Me Smalls",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Barq’s Root Beer base, Toasted Marshmallow, Vanilla Cream",
        subtitleText2: "Asking who wants to play lime toss on the prep table",
        subtitleText3: "Smalls is the little man of the group. The fact that he’s loved by kids coming to Thirst has made him assume a youthful personality.. He’s young, curious, and has a lot to learn. ",
    },
    {
        id: "17",
        frontImg: Frontimg17,
        title: "April O’Neal ",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Mountain Dew, pomegranate, fresh lime",
        subtitleText2: "Sunbathing on the patio of the St. George Store",
        subtitleText3: "A summer girl, April O’Neal is that tart mixer gal you always find yourself (daydreaming about?) wanting. While most Mountain Dew mixers have a cream to them, she has a tart style that can sometimes come off as a little conceited…but for some reason you love it.",
    },
    {
        id: "18",
        frontImg: Frontimg18,
        title: "Hissy Fit",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Diet Coke, pineapple, coconut, coconut cream",
        subtitleText2: "Reapplying her lipstick",
        subtitleText3: "Mrs. Hissy will throw a hissy at YOU if you’re not careful. She’s a girly girl and not afraid to be a drama princess. Growing up in the Diet Coke fam AND being a cream mixer makes her the go-to choice for young male Thirsty characters looking for a hot date. ",
    },
    {
        id: "19",
        frontImg: Frontimg19,
        title: "Death Star",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Dr. Pepper, coconut, blackberry",
        subtitleText2: "Practicing his karate moves on Pretzel Dog",
        subtitleText3: "Mess with Thirsty Creatures… mess with DEATH STAR. Strength, agility, and honor describe this tough Dr. Pepper based mixer. He didn’t get the nickname “Dr. Death” without earning it. He will fight and defend at all costs to serve the Thirsty Nation.",
    },
    {
        id: "20",
        frontImg: Frontimg20,
        title: "Pubbin",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Root Beer with vanilla ice cream",
        subtitleText2: "Letting the root beer flow over his 44 oz cup as he sits under the soda spout",
        subtitleText3: "Mr. Pubbin loves nothing more than a cold, frothy, ice cream bath. He enjoys the simple things in life like pairing with pretzel bites, and encourages others to do the same.",
    },
    {
        id: "21",
        frontImg: Frontimg21,
        title: "The Hustle",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Yellow redbull, grapefruit, fresh lime, Fresca",
        subtitleText2: "At the gym at 4am",
        subtitleText3: "When they said it “gives you wings”, Mr. Hustle didn’t believe them until he sprouted a pair for himself and took his hustle mentality and capabilities (mojo and groove?) to a whole new level.",
    },
    {
        id: "22",
        frontImg: Frontimg22,
        title: "Frat Star",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Monster Energy, your choice of flavor shot, Mountain Dew",
        subtitleText2: "Trying to get people to play a round of Dew Pong",
        subtitleText3: "We all know that one friend who could never move on from college…well that’s Mr. Frat Star. He’s loud, vivacious, always has a smile, always sports his frat shorts and visor, and is always down for a party. ",
    },
    {
        id: "23",
        frontImg: Frontimg23,
        title: "Kick Back Ricky",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Sprite, tons of fresh lime, raspberry or grape",
        subtitleText2: "Outside kicking back in a lawn chair while watching the drive thru line on a summer day",
        subtitleText3: "Everyone wants to kick back, relax, and enjoy a drink with “KB” as they call him. Something about him just makes you want to take a load off. He’s easy to talk to, easygoing, and an easy pick for most “likeable” thirsty creature.",
    },
    {
        id: "24",
        frontImg: Frontimg24,
        title: "I’m Your Huckleberry",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Coke, raspberry puree, blackberry",
        subtitleText2: "Trying to solve a murder mystery",
        subtitleText3: "Young “Huckleberry” is young, curious, and always finding her way into trouble. She’s always looking for a friend to join her in her next adventure. She got her name by discovering the very first Huckleberry tree on  Mount Thirsty.",
    },
    {
        id: "25",
        frontImg: Frontimg25,
        title: "No Diggity No Doubt",
        subtitle1: "Blood Type:",
        subtitle2: "Most Likely To Be Found:",
        subtitle3: "Description: ",
        subtitleText1: "Pepsi, raspberry puree, vanilla",
        subtitleText2: "Staring down drinks as they get filled up on the soda grate",
        subtitleText3: "The gangster of the group, No Dig No Doubt will always demand the respect he deserves…or else! He loves to sport his bling bling, sideways hat, and roll with the boys.",
    },
]