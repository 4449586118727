export const NftcollectionsData = [
    {
        id: "01",
        text: "Downtown #1"
    },
    {
        id: "02",
        text: "Millcreek #2"
    },
    {
        id: "03",
        text: "West Jordan #3"
    },
    {
        id: "04",
        text: "Bountiful #4"
    },
    {
        id: "05",
        text: "Tooele #5"
    },
    {
        id: "06",
        text: "Midvale #6"
    },
    {
        id: "07",
        text: "St. George #7"
    },
    {
        id: "08",
        text: "Layton #8"
    },
]